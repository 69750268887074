:root {
	--mainColor: #0068ff;
	--whiteColor: #fcfcfc;
	--backgroundColor: #111111;
	--buttonHoverColor: #3485fe;
}

*,
html {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}
body {
	background-color: var(--backgroundColor);
	padding: 0;
	margin: 0;
	font-family: "Roboto", "Reem Kufi", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	color: inherit;
	text-decoration: none;
}

@media screen and (max-device-width: 600px) {
	body {
		-webkit-text-size-adjust: 100%;
	}
}

/* slider */
.slick-next {
	right: -50px !important;
}

.slick-prev {
	left: -50px !important;
}

.slick-prev:before,
.slick-next:before {
	font-size: 25px !important;
}

@media screen and (max-device-width: 600px) {
	.slick-prev:before,
	.slick-next:before {
		font-size: 20px !important;
	}

	.slick-next {
		right: -25px !important;
	}

	.slick-prev {
		left: -25px !important;
	}
}

@media screen and (min-device-width: 601px) and (max-device-width: 1024px) {
	.slick-prev:before,
	.slick-next:before {
		font-size: 20px !important;
	}
	.slick-next {
		right: -25px !important;
	}

	.slick-prev {
		left: -25px !important;
	}
}
